<template>
  <div>
    <v-row>
      <template v-for="item in items">
        <v-col cols="6" :key="item.name">
          <!-- Number -->
          <v-text-field
            v-if="isNumber(item.type)"
            v-model.number="values[item.name]"
            :label="item.label"
            prepend-icon="mdi-numeric"
            type="number"
            outlined
            :dense="dense"
          ></v-text-field>

          <!-- Boolean -->
          <template v-else-if="isBoolean(item.type)">
            {{ item.label }}</template
          >

          <!-- Date -->
          <!-- <v-menu
            v-else-if="isDate(item.type)"
            v-model="conditions[item.name]"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :dense="dense"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="values[key]"
                :label="item.label"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                :dense="dense"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="values[item.name]"
              locale="jp"
              @input="conditions[item.name] = false"
            ></v-date-picker>
          </v-menu> -->
          <v-menu
            v-else-if="isDate(item.type)"
            v-model="conditions[item.name]"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :dense="dense"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="values[item.name]"
                :label="item.label"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
                outlined
                :dense="dense"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="values[item.name]"
              locale="jp-ja"
              :day-format="date => new Date(date).getDate()"
            ></v-date-picker>
          </v-menu>
          <v-autocomplete
            v-else-if="isPulldown(item.type)"
            v-model="values[item.code]"
            prepend-icon="mdi-form-select"
            :items="item.models"
            :label="item.label"
            outlined
            :dense="dense"
            item-text="name"
            item-value="code"
          >
          </v-autocomplete>
          <!-- Time -->
          <!-- <TimePicker
            v-else-if="isTime(item.type)"
            v-model="values[item.name]"
            prepend-icon="mdi-clock-end"
            :label="item.label"
            dense
            outlined
          /> -->
          <v-menu
            v-else-if="isTime(item.type)"
            v-model="conditions[item.name]"
            ref="menu"
            :return-value.sync="time"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :dense="dense"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="values[item.name]"
                :label="item.label"
                prepend-icon="mdi-clock-end"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
                outlined
                :dense="dense"
              ></v-text-field>
            </template>
            <v-time-picker
              v-model="values[item.name]"
              format="24hr"
              @click:minute="$refs.menu.save(conditions[item.name])"
            ></v-time-picker>
          </v-menu>
          <!-- Text -->
          <v-text-field
            v-else
            v-model="values[item.name]"
            prepend-icon="mdi-text"
            :label="item.label"
            outlined
            :dense="dense"
          ></v-text-field>
        </v-col>

        <!-- Conditions
        <v-col cols="6" :key="i"> -->
        <!-- <v-select
            v-if="isNumber(item.type)"
            label="条件"
            :items="['超過', '以上', '同等', '以下', '未満', '以外']"
            v-model="conditions[item.name]"
            outlined
            autocomplete
            :dense="dense"
          ></v-select> -->

        <!-- <v-select
            v-else-if="isBoolean(item.type)"
            label="条件"
            :items="['', '真', '偽']"
            v-model="conditions[item.name]"
            outlined
            autocomplete
            :dense="dense"
          ></v-select> -->

        <!-- <v-radio-group v-else v-model="conditions[item.name]" row dense>
            <v-radio label="部分一致" value="^[value]$"></v-radio>
            <v-radio
              label="前方一致"
              :value="`^${values[item.name]}.*`"
            ></v-radio>
            <v-radio label="後方一致" value="radio-2"></v-radio>
          </v-radio-group>
        </v-col> -->
      </template>

      <!-- Fotter -->
      <!-- 
      <v-col>
        <v-text-field
          label="最大検索数"
          suffix="件"
          type="number"
          number
          outlined
          :dense="dense"
        ></v-text-field
      ></v-col>
      <v-col>
        <v-text-field
          value="0"
          label="除外件数"
          suffix="件"
          type="number"
          outlined
          :dense="dense"
        ></v-text-field>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
import Common from "@/mixins/common";

export default {
  name: "TableFilter",
  mixins: [Common],
  props: {
    value: { type: Object },
    dense: Boolean
  },
  computed: {
    values() {
      return this.value.values;
    },
    conditions() {
      return this.value.conditions;
    },
    items() {
      return this.value.items;
    },
    models() {
      return this.value.items.models;
    }
  },
  watch: {
    value(v) {
      console.log("watch", "value", v);
      this.init();
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (!this.value.values) this.value.values = {};
      if (!this.value.conditions) this.value.conditions = {};
    },
    isString(value) {
      return value === String || value === "string";
    },
    isNumber(value) {
      return value === Number || value === "number";
    },
    isDate(value) {
      return value === Date || value === "date";
    },
    isBoolean(value) {
      return value === "boolean";
    },
    isTime(value) {
      return value === "time";
    },
    isPulldown(value) {
      return value === "pulldown";
    }
  }
};
</script>

<style></style>
